<template>
  <div class="contract_wrapper">
    <div>
      <div class="base_box node_box">
        <div class="switch_box both_side">
          <div>
            <div class="align_center delay">
              <img src="../assets/img/wifi.png" alt="" srcset="" />{{
                nodeTime
              }}
              ms
            </div>
            <div class="link">{{ rpcUrl }}</div>
          </div>
          <div class="switch_btn" @click="clickNode">切換節點</div>
        </div>
        <div class="base_title">選擇公鏈</div>
        <div class="base_select mt20" :class="{ select_active: show1 }">
          <div class="content">
            <img
              :src="chainObj[selectChain].logo"
              class="select_img"
              srcset=""
            />{{ chainObj[selectChain].chainName }}
          </div>
          <div class="right_down" @click.stop="show1 = !show1"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in chains"
              @click="switchChain(item.chain)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.chainName
              }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="uploadResult.length == 0">
        <div class="private_title">
          <span class="title"> 上傳私鑰 </span>
        </div>
        <uploadExcel
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        ></uploadExcel>
      </div>
      
      <div v-show="uploadResult.length">
        <div class="private_title">
          <div class="align_center">
            <span class="title">地址信息</span>
            <div class="refresh_box" @click="refreshBalance">
              <img src="../assets/img/a4.png" class="refresh_icon" :class="{refresh_animate:refresh}"/>
              <span class="total_num">刷新</span>
            </div>
          </div>
          <img src="../assets/img/close2.png" class="close" @click="removeAdd" />
        </div>
        <div class="base_box address_list">
          <div class="table_item table_header">
              <div class="status"></div>
              <div class="no"></div>
              <div class="address">操作地址</div>
              <div class="amout">{{chainObj[selectChain].Symbol}}餘額</div>
              <div class="delete"></div>
          </div>
          <div class="table_content">
            <my-scroll>
            <div class="table_item" v-for="(item,index) in wallets" :key="index">
                <img src="../assets/img/a1.png" class="status" srcset="" v-show="!item.loading && item.status">
                <img src="../assets/img/a2.png" class="status" srcset="" v-show="!item.loading && !item.status">
              <div class="status" v-show="item.loading"></div>
              <div class="no">{{index+1}}</div>
              <div class="address">{{showAccount4(item.address)}}</div>
              <div class="amout">{{item.showBalance}}</div>
              <img src="../assets/img/a3.png" @click="deleteAdd(index)" class="delete" srcset="">
            </div>
          </my-scroll>
          </div>
          <div class="table_item table_footer">
              <div class="status"></div>
              <div class="address" style="color:#000">縂操作 {{wallets.length}}</div>
              <div class="address" style="color:#000">執行成功 {{successNum}}</div>
              <div class="amout">{{totalBalance}} {{chainObj[selectChain].Symbol}}</div>
              <div class="delete"></div>
          </div>
          <div class="error_box" v-show="errors.length">
            <div class="error_title">
              <span class="text">以下地址無效或金额無效無法發送</span>
              <div class="delete_box" @click="deleteAll">
                <img src="../assets/img/a3.png" alt="" srcset="">一鍵刪除
              </div>
            </div>
            <div>
              <span v-for="(item,index) in errors" :key="index">第{{item.index}}行<span v-show="index != errors.length-1">、</span></span>无效
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base_box main_box">
      <div class="base_title">被調用合約</div>
      <div class="base_input mt20">
        <input type="text" v-model="toAddress" placeholder="輸入合約地址" />
      </div>
      <div class="base_title">調用輸入</div>
      <div class="base_input mt20">
        <input type="text" v-model="inputData" placeholder="輸入16進制" />
      </div>
      <div class="base_title">
        原生代幣支付({{ chainObj[selectChain].Symbol }})
      </div>
      <div class="base_input mt20">
        <input
          type="text"
          v-model="amount"
          placeholder="若調用的合約需支付原生代幣則輸入數量，默認為0"
        />
      </div>
      <div class="base_title">GAS費支付倍數</div>
      <div class="base_input">
        <input
          type="text"
          v-model="gasMulti"
          placeholder="請輸入GAS費倍數，默認1倍"
        />
      </div>
      <div class="base_btn2" @click="clickpersonal" v-if="personal">簽名驗證</div>
      <div class="base_btn2" v-else @click="clickSend">
        立即執行
      </div>
    </div>
   <Modal
      v-model="amountModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
        <div class="modal_header">
          <div class="modal_title">
            <img src="../assets/img/node_icon.png" alt="" srcset="">切換節點
          </div>
          <img
            src="../assets/img/vipclose.png"
            class="close_icon"
            srcset
            @click="amountModal = false"
          />
        </div>
        <div class="align_center">
          <div class="base_input" style="flex: 1">
            <input
              type="text"
              v-model="tempUrl"
              :placeholder="$t('tool.14')"
            />
          </div>
          <span
            class="base_btn"
            style="width: 100px; margin-left: 20px"
            @click="clickConfirm"
            >確認</span
          >
        </div>
    </Modal>
  </div>
</template>

<script>
import BN from "bn.js";
import { chainObj } from "../config";
import uploadExcel from "@/components/uploadExcelPrivateKey";
import {
showFromWei,
  showAccount2,
  showAccount4,
  clickOutside
} from "../utils";
function countTotal(arr, keyName) {
	let $total = 0;
	$total = arr.reduce(function (total, currentValue, currentIndex, arr){
	    return currentValue[keyName] ? (total + parseFloat(currentValue[keyName])) : total;
	}, 0);
	return $total;
}
export default {
  directives: {clickOutside},
  components: { uploadExcel },
  props: {
    address: {
      type: String,
    }
  },
  data() {
    return {
      chainObj,
      show1: false,
      selectChain: "BSC",
      rpcUrl: chainObj["BSC"].RPC,
      nodeTime: 212,
      uploadResult: [],
      wallets: [],
      errors: [],

      gasMulti: "",
      amount: "",
      inputData: "",
      toAddress: "",
      tempUrl: "",
      amountModal: false,
      // personal: !localStorage.getItem('personal')
      personal: true,
      refresh: false,
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj);
    },
    successNum() {
      return this.wallets.filter(item => item.status).length
    },
    totalBalance() {
      let number = countTotal(this.wallets, 'showBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
    },
  },
  // mounted() {
  //   this.getTime();
  //   window.addEventListener("scroll", this.handleScroll, true);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  methods: {
    handleScroll() {
      this.show1 = false;
    },
    clickNode() {
      this.amountModal = true
    },
    async clickConfirm() {
      try {
        var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.tempUrl));

      let time = await myWeb3.eth.getGasPrice();
      this.rpcUrl = this.tempUrl
      this.amountModal = false
      this.getTime()
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
      
    },
    showAccount2,
    showAccount4,
    removeAdd() {
      this.uploadResult = [];
    },
    deleteAll() {
      let wallets = this.wallets
      this.errors.forEach(item => {
        let index = wallets.findIndex(wallet => wallet.address == item.address)
        wallets.splice(index, 1)
      })
      this.uploadResult = wallets
      this.wallets = wallets
      this.errors = []
    },
    deleteAdd(index) {
      this.wallets.splice(index, 1);
    },
    async getTime() {
      var start = new Date().getTime();

      var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));

      let time = await myWeb3.eth.getGasPrice();
      var end = new Date().getTime();
      console.log("cost is", `${end - start}ms`);
      this.nodeTime = end - start;
    },
    switchChain(chain) {
      this.selectChain = chain;
      this.rpcUrl = chainObj[chain].RPC;
      this.getTime();
      this.show1 = false;
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }
      this.$message.error("Please do not upload files larger than 1m in size.");
      return false;
    },
    handleSuccess({ header, results }) {
      this.errors = []
      if (results.length > 0) {
        // 拿到秘钥
        this.uploadResult = results;
        console.log(results);
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        console.log(myWeb3.eth.accounts);
        try {
          this.uploadResult.forEach(async (item) => {
            let account = myWeb3.eth.accounts.privateKeyToAccount(
              item.privateKey
            );
            console.log(account);
            item.address = account?.address;
            // let balance = await myWeb3.eth.getBalance(account.address);
            // item.balance = balance
            // item.showBalance = showFromWei(balance, 18, 4);
            item.status = false
            item.loading = true
          });
          console.log(this.uploadResult);
          this.wallets = this.uploadResult
          this.batchGetTokenBalance()
        } catch (error) {
          console.log(error);
          this.$message.error("privateKey Error");
        }
      }
    },
    async batchGetTokenBalance() {
        
        setTimeout(() => {
            let wallets = this.wallets;
            let length = wallets.length;
            this.errors = []
            for (let index = 0; index < length; index++) {
              wallets[index].loading = true
                this.getTokenBalance(wallets[index], index);
            }
        }, 30);
    },
    async refreshBalance() {
      this.refresh = true
      setTimeout(() => {
        this.refresh = false
      }, 1000)
      setTimeout(() => {
          let wallets = this.wallets;
          let length = wallets.length;
          this.errors = []
          for (let index = 0; index < length; index++) {
            wallets[index].loading = true
            wallets[index].status = false
              this.getTokenBalance(wallets[index], index, 'refresh');
          }
      }, 30);
    },
    async getTokenBalance(wallet, index, refresh) {
        try {
            
            var Web3 = require("web3");
            const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
            
            
            let balance = await myWeb3.eth.getBalance(wallet.address);
            let showBalance = showFromWei(balance, 18, 4);
            wallet.balance = balance;
            wallet.showBalance = showBalance;

            

            this.$set(this.wallets,index,wallet)
        } catch (e) {
            console.log("e", e);
        } finally {
          if(!wallet.address || wallet.showBalance == 0) {
              this.errors.push({
                index: index+1,
                address: wallet.address
              })
            }
          if(refresh == 'refresh' && index == (this.wallets.length - 1)) {
            this.$message('刷新成功');
          }
        }
    },
    async clickpersonal() {
      let that = this
      const web3 = window.web3;
     
      web3.eth.personal.sign("check_wallet", this.address).then(async(tx) => {
        that.personal = false
        localStorage.setItem('personal', true)
      })
    },
    async clickSend() {
      if (!this.uploadResult.length) {
        this.$message.error("请上傳私鑰");

        return;
      }
      if (!this.toAddress) {
        this.$message.error("請輸入合约地址");
        return;
      }
      if (!this.inputData) {
        this.$message.error("請輸入Input data");
        return;
      }
      this.message2 = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      let wallets = this.wallets;
      let length = wallets.length;
      for (let index = 0; index < length; index++) {
          this.sendTx(wallets[index], index);
      }
      // var Web3 = require("web3");
      // const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
      // this.uploadResult.forEach((item) => {
      //   this.sendTx(myWeb3, item.privateKey, item.address);
      // });
    },
    async genRawTx(web3, fromAddress) {
      //获取当前账户的交易数量
      let number = web3.utils.toHex(
        await web3.eth.getTransactionCount(fromAddress)
      );
 
      var gasPrice = await web3.eth.getGasPrice();
      console.log(gasPrice)
      // gasPrice = new BN(gasPrice, 10);
      // //gas倍数
      let gasMulti = this.gasMulti;
      if (!gasMulti) {
        gasMulti = 1;
      }
      // gasMulti = parseFloat(gasMulti);
      // gasMulti = parseInt(gasMulti * 100);
      // gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));
      const gas = await web3.eth.estimateGas({
        from: fromAddress,
        to: this.toAddress,
        value: this.amount
          ? web3.utils.toHex(web3.utils.toWei(this.amount))
          : "",
        gasPrice: gasPrice,
        nonce: number,
        data: this.inputData
      });
      console.log(gas)
      let rawTx = {
        nonce: number,
        gas: gas * gasMulti,
        gasPrice,
        // gasLimit: web3.utils.toHex(1000000),
        to: this.toAddress, //接收方地址
        from: fromAddress, //发送方地址
        value: this.amount
          ? web3.utils.toHex(web3.utils.toWei(this.amount))
          : "", //发送金额，单位为wei
        data: this.inputData,
      };
      return rawTx;
    },
    async sendTx(wallet, index) {
      var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
      try {
        let rawTx = await this.genRawTx(myWeb3, wallet.address); //根据题目要求构造的data
        console.log(rawTx);
        let signedTx = await web3.eth.accounts.signTransaction(rawTx, wallet.privateKey);
        console.log(signedTx)
        let res = await web3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        wallet.loading = false
          if (!res.status) {
            wallet.status = false
          }
          wallet.status = true
          
          this.$set(this.wallets,index,wallet)
      } catch (e) {
        console.log(e.message);
        wallet.loading = false
        wallet.status = false
        this.$set(this.wallets,index,wallet)
        // this.$message.error(e.message);
      } finally {
        if(this.message2 && index == (this.wallets.length - 1)) {
          setTimeout(()=> {
          this.message2.close()
          this.$message("執行完畢");
          }, 3000)
        }
        
      }
    },
  },
};
</script>

<style lang="less">
@import "../assets/pc/contract.less";
@import "../assets/mobile/contract.less";
</style>